import React, { useState } from "react";
import { useFormik } from "formik";
import Layout from "../layout/Layout";
import useSearch from "../hooks/searchspring/useSearch";
import ProductFilters from "../components/ProductFilters";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import ProductSortFilter from "../components/searchSpring/ProductSortFilter";
import ProductListing from "../components/products/ProductListing";
import Pagination from "../components/common/Pagination";
import { SearchContext } from "../context/SearchContext";

function SearchPage({ location }) {
  const [view, setProductView] = useState("grid");

  const formik = useFormik({
    initialValues: { sortBy: "" },
    onSubmit: (values) => {
      console.log("filters", values);
    },
  });

  const {
    products,
    sortingOptions,
    isLoading,
    pagination,
    handleSortOption,
    breadcrumbs,
    facets,
    handleFilterChange,
    filterSummary,
    handleRemoveFilter,
    handlePageChange,
    applyFilter,
    handlePriceChange,
    onPriceReset
  } = useSearch(formik.values["sortBy"]);

  const { breadcrumbTitle } = breadcrumbs;
  const { page, totalPages } = pagination;

  const contextValues = {
    isLoading,
    products,
    facets,
    view,
    filterSummary,
    sortingOptions,
    setProductView,
    handleSortOption,
    handleFilterChange,
    handleRemoveFilter,
    applyFilter,
    handlePriceChange,
    onPriceReset
  };

  return (
    <Layout>
      <SearchContext.Provider value={contextValues}>
        <div className="page-wrapper">
          <div className="page-heading-section text-center">
            <BreadCrumbs location={location} title={breadcrumbTitle} />
          </div>
          <div className="container">
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar">
                <ProductFilters />
              </div>
              <div className="col page-content">
                <div className="page-action-bar flex justify-space vertical-middle">
                  <ProductSortFilter />
                </div>
                <ProductListing />
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={totalPages}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </SearchContext.Provider>
    </Layout>
  );
}

export default SearchPage;
